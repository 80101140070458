import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadingHttp = new BehaviorSubject(false);

  constructor() {}

  start(): void {
    this.loadingHttp.next(true);
  }

  stop(): void {
    this.loadingHttp.next(false);
  }

  inProgress(): boolean {
    return !!this.loadingHttp.value;
  }
}
